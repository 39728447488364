import React, { useState, useEffect} from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import TruckImage1 from "./van1.jpg"; // Image import
import TruckImage2 from "./van2.jpg"; // Image import

interface VanCarouselProps {
    language: string;
  }

const VanCarousel: React.FC<VanCarouselProps> = ({ language }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const images = [
    {
      src: TruckImage1,
      alt:
        language === "en" ? "Van rear view" : "Vista trasera de la camioneta",
    },
    {
      src: TruckImage2,
      alt:
        language === "en" ? "Van side view" : "Vista lateral de la camioneta",
    },
    // { src: '/van3.png', alt: language === 'en' ? 'Van front view' : 'Vista frontal de la camioneta' }
  ];
  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => 
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 9000); // 5 seconds
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isAutoPlaying, images.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    setIsAutoPlaying(false); // Pause autoplay when user interacts
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setIsAutoPlaying(false); // Pause autoplay when user interacts
  };

  // Resume autoplay when mouse leaves the carousel
  const handleMouseLeave = () => {
    setIsAutoPlaying(true);
  };

  // Pause autoplay when mouse enters the carousel
  const handleMouseEnter = () => {
    setIsAutoPlaying(false);
  };

  return (
    <div 
      className="van-carousel"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="carousel-container">
        <button 
          className="carousel-button left" 
          onClick={prevSlide}
          aria-label={language === 'en' ? 'Previous image' : 'Imagen anterior'}
        >
          <ChevronLeft size={40} />
        </button>
        
        <div className="image-container">
          <img 
            src={images[currentIndex].src} 
            alt={images[currentIndex].alt} 
            className="carousel-image"
          />
        </div>

        <button 
          className="carousel-button right" 
          onClick={nextSlide}
          aria-label={language === 'en' ? 'Next image' : 'Siguiente imagen'}
        >
          <ChevronRight size={40} />
        </button>
      </div>

      <div className="carousel-dots">
        {images.map((_, index) => (
          <button
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => {
              setCurrentIndex(index);
              setIsAutoPlaying(false);
            }}
            aria-label={`${language === 'en' ? 'Go to image' : 'Ir a imagen'} ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default VanCarousel;