import React from "react";
// import Button from "@mui/material/Button";
import "./JobRequestForm.css";

interface JobRequestFormProps {
  language: string;
  googleFormUrl: Record<string, string>;
}

const JobRequestForm: React.FC<JobRequestFormProps> = ({
  language,
  googleFormUrl,
}) => {


  const getCalendlyUrl = () => {
   
      return "https://calendly.com/miguelomadrigal/when"; // Weekday Calendly link
    // Weekend Calendly link
  };

  return (
    <section id="job-request-form">
      <h2>
        {language === "en"
          ? "Submit a Job Request"
          : "Enviar una Solicitud de Trabajo"}
      </h2>

      {/* Weekday and Weekend Selection */}
      {/* <div className="schedule-selector">
        <Button
          variant={scheduleType === "weekday" ? "contained" : "outlined"}
          color="success"
          onClick={() => handleScheduleChange("weekday")}
          sx={{ borderRadius: "50px", padding: "12px 25px", margin: "0 10px" }}
        >
          {language === "en" ? "Weekday" : "Días de Semana"}
        </Button>
        <Button
          variant={scheduleType === "weekend" ? "contained" : "outlined"}
          color="success"
          onClick={() => handleScheduleChange("weekend")}
          sx={{ borderRadius: "50px", padding: "12px 25px", margin: "0 10px" }}
        >
          {language === "en" ? "Weekend" : "Fin de Semana"}
        </Button>
      </div> */}

      {/* Calendly Embed */}
      <div className="calendly-embed-container">
        <iframe
          title="Calendar"
          src={getCalendlyUrl()}
          width="100%"
          height="780px"
          frameBorder="0"
        ></iframe>
      </div>
    </section>
  );
};

export default JobRequestForm;
