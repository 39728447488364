import React from "react";
// import truckImage from "./vans.png"; // Image import
import "./HeroSection.scss"; // Updated SCSS import
import JobRequestForm from "./JobRequestForm"; // Ensure the form component is imported
import "./ServicesSection.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";
import VanCarousel from "./VanCarousel";
import SnowPlow from "./SnowPlow.png";
interface HomePageProps {
  language: string;
}

const HomePage: React.FC<HomePageProps> = ({ language }) => {
  const googleFormUrl: Record<string, string> = {
    en: "https://docs.google.com/forms/d/e/1FAIpQLSdAricZYNbayDMihYQWgXWPOjKLgkunWqV_ugrrWyyR7BDgYA/viewform", // Replace with actual English form URL
    es: "https://docs.google.com/forms/d/e/1FAIpQLSdAricZYNbayDMihYQWgXWPOjKLgkunWqV_ugrrWyyR7BDgYA/viewform?usp=sf_link", // Replace with actual Spanish form URL
  };

  return (
    <div className="homepage-container">
      {/* Hero Section */}

      {/* Job Request Form Section */}
      <JobRequestForm language={language} googleFormUrl={googleFormUrl} />

      {/* Services Section */}
      <section id="services" className="services">
        <h2>{language === "en" ? "Our Services" : "Nuestros Servicios"}</h2>
        <List>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en"
                  ? "Hauling furniture (new and old)"
                  : "Transporte de muebles (nuevos y usados)"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en" ? "Junk removal" : "Remoción de basura"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en" ? "Moving services" : "Servicios de mudanza"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={language === "en" ? "Local moves" : "Mudanzas locales"}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en"
                  ? "Long distance moves"
                  : "Mudanzas a larga distancia"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en" ? "Office moves" : "Mudanzas de oficina"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en"
                  ? "Store pickup furniture"
                  : "Recogida de muebles de la tienda"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en"
                  ? "Removal of hot tubs"
                  : "Remoción de jacuzzis"
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CircleIcon fontSize="small" style={{ fontSize: "0.6em" }} />{" "}
              {/* Adjusted size */}
            </ListItemIcon>
            <ListItemText
              primary={
                language === "en" ? "24/7 Snow Plowing" : "24/7 quitando nieve"
              }
            />
          </ListItem>
        </List>
      </section>

      {/* Contact Section */}
      {/* Van Section */}
      <section id="van" className="van-section">
        <h2>{language === "en" ? "Our Van" : "Nuestra Camioneta"}</h2>
        <VanCarousel language={language} />
      </section>
      <section id="snow plow" className="plow-section">
        <h2>{language === "en" ? "24/7 Snow Plow" : "24/7 Quitanieves"}</h2>
        <img src={SnowPlow} alt="Snow plow" />
      </section>
    </div>
  );
};

export default HomePage;
