import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import "./Navbar.css";

interface NavbarProps {
  language: string;
  setLanguage: (lang: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ language, setLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsOpen(open);
    };

  return (
    <header>
      <nav className="navbar">
        <ul className="nav-links">
          <li>
            <a href="#home">{language === "en" ? "Home" : "Inicio"}</a>
          </li>
          <li>
            <a href="#services">
              {language === "en" ? "Services" : "Servicios"}
            </a>
          </li>
          <li>
            <a href="#van">{language === "en" ? "Van" : "Van"}</a>
          </li>
          <li>
            <a href="#snow plow">{language === "en" ? "Snow plow" : "Quitanieves"}</a>
          </li>
        </ul>

        <div className="center-content">
          <h1>
            {language === "en"
              ? "Mike's Hauling Service"
              : "Servicio de Carga de Mike"}
          </h1>
          <p className="phone-number">{"(773) 934-4280"}</p>
        </div>

        <div className="nav-buttons">
          <Button
            variant={language === "en" ? "contained" : "outlined"}
            color="success"
            onClick={() => setLanguage("en")}
            sx={{ borderRadius: "50px", padding: "12px 25px" }}
          >
            English
          </Button>
          <Button
            variant={language === "es" ? "contained" : "outlined"}
            color="success"
            onClick={() => setLanguage("es")}
            sx={{ borderRadius: "50px", padding: "12px 25px" }}
          >
            Español
          </Button>
        </div>

        <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
          <List>
            <ListItem component="a" href="#home">
              <ListItemText primary={language === "en" ? "Home" : "Inicio"} />
            </ListItem>
            <ListItem component="a" href="#services">
              <ListItemText
                primary={language === "en" ? "Services" : "Servicios"}
              />
            </ListItem>
          </List>
        </Drawer>
      </nav>
    </header>
  );
};

export default Navbar;
