import React, { useState } from "react";
import Navbar from "./Navbar";
import HomePage from "./HomePage";
import JobRequestForm from "./JobRequestForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  const [language, setLanguage] = useState("en");

  // Google Form URLs for English and Spanish
  const googleFormUrl: Record<string, string> = {
    en: "https://docs.google.com/forms/d/e/1FAIpQLSdAricZYNbayDMihYQWgXWPOjKLgkunWqV_ugrrWyyR7BDgYA/viewform?usp=sf_link",
    es: "https://docs.google.com/forms/spanish-form-url", // Replace with the actual Spanish form URL
  };

  return (
    <Router>
      <Navbar language={language} setLanguage={setLanguage} /> {/* Navbar with language control */}
      <Routes>
        <Route path="/" element={<HomePage language={language} />} />
        <Route
          path="/job-request"
          element={<JobRequestForm language={language} googleFormUrl={googleFormUrl} />} // Pass language and form URL to JobRequestForm
        />
      </Routes>
    </Router>
  );
};

export default App;
